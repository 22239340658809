import React, { useState } from 'react';
import LMSButton from 'components/LMSButton';

type DifficultLevelType = 'easy' | 'medium' | 'hard';

interface Props {
  onChange: (level: DifficultLevelType) => void;
}

const LEVELS: { value: DifficultLevelType; label: string }[] = [
  {
    value: 'easy',
    label: 'Легкий: 20 пар картинок, время на выполнение – 3 минут на 1 пару картинок',
  },
  {
    value: 'medium',
    label: 'Средний: 10 пар картинок, время на выполнение – 2 минуты на 1 пару картинок',
  },
  {
    value: 'hard',
    label: 'Сложный: 10 пар картинок, время на выполнение – 1 минута на 1 пару картинок',
  },
];

const PathfinderDifficultLevel = ({ onChange }: Props) => {
  const [difficultLevel, setDifficultLevel] = useState<DifficultLevelType | null>(null);

  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] h-[500px] rounded-[20px] theme-student-card m-auto p-5">
      <div className="flex flex-col space-y-4 items-center">
        <p className="text-[18px] text-center leading-6 max-w-[512px]">
          Выберите уровень сложности:
        </p>
        <div className="flex flex-col space-y-4">
          {LEVELS.map((level) => (
            <button
              key={level.value}
              type="button"
              className={`w-full h-[63px] bg-transparent justify-center items-center rounded-2xl border px-10 text-current text-sm md:text-bas outline-none ${
                level.value === difficultLevel ? 'theme-border--primary' : 'border-[#E7E7E7]'
              }`}
              onClick={() => setDifficultLevel(level.value)}
            >
              {level.label}
            </button>
          ))}
        </div>
      </div>
      <LMSButton
        disabled={!difficultLevel}
        onClick={() => {
          difficultLevel && onChange(difficultLevel);
        }}
      >
        Начать игру
      </LMSButton>
    </div>
  );
};

export default PathfinderDifficultLevel;
