import React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';

import { fetchGameResults } from '../../api/repository/games';
import Header from '../../components/Header';
import MathTest from '../../components/MathTest';
import MultiplicationTable from '../../components/MultiplicationTable';
import Title from '../../components/Title';

interface ITaskLevel {
  id: number;
  title: string;
  description: string;
  difficulty: 'easy' | 'medium' | 'hard';
  score: number;
  bestScore: number | null;
  time: number;
}

interface IGame {
  date: string;
  difficulty: 'easy' | 'medium' | 'hard';
  game: string;
  id: number;
  score: number;
  time: number;
}

const TASK_LEVELS: ITaskLevel[] = [
  {
    id: 1,
    title: 'Легкий',
    description: 'Сложение и вычитание чисел',
    difficulty: 'easy',
    score: 0,
    bestScore: null,
    time: 300,
  },
  {
    id: 2,
    title: 'Средний',
    description: 'Умножение и деление чисел',
    difficulty: 'medium',
    score: 0,
    bestScore: null,
    time: 180,
  },
  {
    id: 3,
    title: 'Сложный',
    description: 'Все виды математических операций',
    difficulty: 'hard',
    score: 0,
    bestScore: null,
    time: 90,
  },
];

const DEFAULT_TASK_LEVEL = TASK_LEVELS[1];

const MathVortex = () => {
  const [selectedLvl, setSelectedLvl] = useState<ITaskLevel>(DEFAULT_TASK_LEVEL);
  const [activeLvl, setActiveLvl] = useState<ITaskLevel>();
  const [gameResults, setGameResults] = useState<IGame[]>([]);

  const findBestScore = (difficulty: 'easy' | 'medium' | 'hard') => {
    return gameResults
      .filter((item) => item.difficulty === difficulty)
      .reduce((acc, item) => {
        return acc > item.score ? acc : item.score;
      }, 0);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeLvl]);

  useEffect(() => {
    fetchGameResults('vortex').then((res) => {
      setGameResults(res.result);
    });
  }, []);

  return (
    <>
      <Header />
      <div className="w-full flex flex-col items-center px-4 pt-12 md:pt-20 space-y-10">
        {!activeLvl?.id && (
          <div className="theme-student-card flex flex-col w-full max-w-[1200px] rounded-[20px] p-16">
            <Title className="text-center">Математический вихрь</Title>
            <section className="flex flex-col gap-y-8 items-center pt-10">
              <h2 className="text-2xl md:text-[32px]">Выберите уровень сложности</h2>
              <div className="flex flex-row bg-[#f1f4ff] rounded-full p-1">
                {TASK_LEVELS.map((item) => (
                  <LMSButton
                    key={item.id}
                    onClick={() => setSelectedLvl(item)}
                    className={selectedLvl.id === item.id ? '' : '!bg-transparent !text-black'}
                  >
                    {item.title}
                  </LMSButton>
                ))}
              </div>
              {selectedLvl && (
                <p>
                  {selectedLvl?.description} / {selectedLvl?.time} сек.
                </p>
              )}
              {findBestScore(selectedLvl.difficulty) > 0 && (
                <span>Ваш лучший результат - {findBestScore(selectedLvl.difficulty)}%</span>
              )}
              <LMSButton
                className="bg-white text-black"
                suffix={<ArrowRight />}
                onClick={() => setActiveLvl(selectedLvl)}
              >
                Начать
              </LMSButton>
            </section>
          </div>
        )}

        {!activeLvl?.id && (
          <section className="theme-student-card rounded-[20px] w-full max-w-[1200px] flex flex-col items-center gap-y-10 p-16">
            <Title className="text-center">Тренировка</Title>
            <div className="flex justify-between gap-x-10 w-full">
              <MultiplicationTable />
            </div>
          </section>
        )}

        {activeLvl?.id && (
          <MathTest
            type={activeLvl.difficulty}
            time={activeLvl.time}
            onClose={() => {
              setActiveLvl(undefined);
              setSelectedLvl(DEFAULT_TASK_LEVEL);
            }}
          />
        )}
      </div>
    </>
  );
};

export default MathVortex;
