import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import LMSButton from 'components/LMSButton';
import { useNotification } from 'hooks/notification';

import { sendGameResults } from '../../api/repository/games';
import Timer from '../Timer';
import Title from '../Title';

interface Props {
  type: 'easy' | 'medium' | 'hard';
  time: number;
  onClose?: () => void;
}

type QuestionType = 'easy' | 'medium' | 'hard';

interface MathQuestion {
  id: string;
  question: string;
  answer: string;
}

const MathTest: React.FC<Props> = ({ type = 'easy', onClose, time = 300 }) => {
  const { control, handleSubmit, reset } = useForm();
  const notify = useNotification();
  const [step, setStep] = useState<1 | 2>(1);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [countUnCorrectAnswers, setCountUnCorrectAnswers] = useState<number>(0);

  const closeTask = () => {
    onClose && onClose();
  };

  const cancelTest = () => {
    notify.error('Время вышло');
    setStep(2);
  };

  const generateMathTests = (type: QuestionType): MathQuestion[] => {
    const questions: MathQuestion[] = [];
    const operations = {
      easy: ['+', '-'],
      medium: ['*', '/'],
    };

    const generateQuestion = (operationType: 'easy' | 'medium'): MathQuestion => {
      const op =
        operations[operationType][Math.floor(Math.random() * operations[operationType].length)];
      let num1, num2, question, answer, id;

      switch (op) {
        case '+':
          id = `question-${questions.length + 1}`;
          num1 = Math.floor(Math.random() * 100) + 1;
          num2 = Math.floor(Math.random() * 100) + 1;
          answer = String(num1 + num2);
          question = `${num1} + ${num2} =`;
          break;
        case '-':
          id = `question-${questions.length + 1}`;
          num1 = Math.floor(Math.random() * 100) + 1;
          num2 = Math.floor(Math.random() * num1) + 1; // num2 <= num1 to avoid negative result
          answer = String(num1 - num2);
          question = `${num1} - ${num2} =`;
          break;
        case '*':
          id = `question-${questions.length + 1}`;
          num1 = Math.floor(Math.random() * 10) + 1;
          num2 = Math.floor(Math.random() * 10) + 1;
          answer = String(num1 * num2);
          question = `${num1} × ${num2} =`;
          break;
        case '/':
          id = `question-${questions.length + 1}`;
          num2 = Math.floor(Math.random() * 9) + 1; // num2 > 0 to avoid division by zero
          num1 = num2 * (Math.floor(Math.random() * 10) + 1); // ensures integer result
          answer = String(num1 / num2);
          question = `${num1} / ${num2} =`;
          break;
        default:
          throw new Error('Unknown operation type');
      }

      return { id, question, answer };
    };

    if (type === 'hard') {
      for (let i = 1; i <= 20; i++) {
        const easyQuestion = generateQuestion('easy');
        const mediumQuestion = generateQuestion('medium');
        questions.push({
          id: `question-${i}`,
          question: easyQuestion.question,
          answer: easyQuestion.answer.toString(),
        });
        questions.push({
          id: `question-${i + 10}`,
          question: mediumQuestion.question,
          answer: mediumQuestion.answer.toString(),
        });
      }
    } else {
      for (let i = 1; i <= 40; i++) {
        const question = generateQuestion(type);
        questions.push({
          id: `question-${i}`,
          question: question.question,
          answer: question.answer.toString(),
        });
      }
    }

    return questions;
  };

  const MATH_TEST_EASY = generateMathTests('easy');
  const MATH_TEST_MEDIUM = generateMathTests('medium');
  const MATH_TEST_HARD = generateMathTests('hard');

  const MATH_TEST =
    type === 'easy' ? MATH_TEST_EASY : type === 'medium' ? MATH_TEST_MEDIUM : MATH_TEST_HARD;

  const onReset = () => {
    reset();
    setCountCorrectAnswers(0);
    setCountUnCorrectAnswers(0);
    setStep(1);
  };

  const onSubmit = (values: Record<string, string>) => {
    const correctAnswersCount = MATH_TEST.filter((test) => {
      if (test.answer === values[test.id]) {
        return true;
      }
      return false;
    }).length;
    setCountCorrectAnswers(correctAnswersCount);
    setCountUnCorrectAnswers(MATH_TEST.length - correctAnswersCount);
    setStep(2);

    sendGameResults({
      game: 'vortex',
      difficulty: type,
      score: Math.ceil((correctAnswersCount * 100) / MATH_TEST.length),
      time,
    }).then(() => {
      notify.success('Результаты успешно отправлены');
    });
  };

  const mathType = (type: QuestionType) => {
    switch (type) {
      case 'easy':
        return 'Легкий уровень';
      case 'medium':
        return 'Средний уровень';
      case 'hard':
        return 'Сложный уровень';
      default:
        return 'Неизвестный уровень';
    }
  };

  return (
    <div className="theme-student-card rounded-[20px] w-full max-w-[1200px] flex flex-col items-center space-y-10 p-16">
      <div className="w-full items-start">
        <LMSButton prefix={<ArrowLeft />} onClick={() => closeTask()}>
          Назад
        </LMSButton>
      </div>

      <Title className="text-center">Математический вихрь</Title>

      <h2 className="text-2xl md:text-[32px]">{mathType(type)}</h2>

      {step === 1 ? (
        <div className="flex flex-col space-y-10">
          <Timer time={time} onClose={() => cancelTest()} />
          <div className="math-vortex-grid">
            {MATH_TEST.map((test, index) => (
              <div key={index} className="flex flex-row items-center space-x-4">
                <p className="text-2xl text-nowrap w-full">
                  {index + 1}) {test.question}
                </p>
                <Controller
                  name={test.id}
                  control={control}
                  rules={{
                    required: { message: 'Введите ответ', value: true },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full rounded-[14px]">
                      <input
                        type="number"
                        {...field}
                        className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                          error ? '!border-red-500' : 'border-white'
                        }`}
                      />
                      {error && <p className="text-red-500">{error.message}</p>}{' '}
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-2 w-full justify-center">
            <LMSButton
              onClick={handleSubmit(onSubmit)}
              className="fixed bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
            >
              Готово
            </LMSButton>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-6">
          <p>Количество вопросов - {MATH_TEST.length}</p>
          <p>Верных ответов - {countCorrectAnswers}</p>
          <p>Неправильных ответов - {countUnCorrectAnswers}</p>

          <LMSButton
            onClick={onReset}
            className="fixed bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
          >
            Попробовать еще раз
          </LMSButton>
        </div>
      )}
    </div>
  );
};

export default MathTest;
