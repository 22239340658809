import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Finger } from 'assets/icons/finger.svg';
import LMSButton from 'components/LMSButton';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import Timer from '../Timer';

type DifficultLevelType = 'easy' | 'medium' | 'hard';

const QUESTIONS_EASY = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder1.png'),
    coords: [
      { x: 665, y: 30 },
      { x: 597, y: 246 },
      { x: 723, y: 325 },
      { x: 648, y: 140 },
      { x: 631, y: 91 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder2.png'),
    coords: [
      { x: 654, y: 28 },
      { x: 423, y: 207 },
      { x: 523, y: 226 },
      { x: 541, y: 327 },
      { x: 646, y: 384 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder3.png'),
    coords: [
      { x: 466, y: 101 },
      { x: 711, y: 157 },
      { x: 574, y: 148 },
      { x: 440, y: 325 },
      { x: 684, y: 363 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder5.png'),
    coords: [
      { x: 731, y: 56 },
      { x: 609, y: 171 },
      { x: 778, y: 211 },
      { x: 619, y: 316 },
      { x: 548, y: 315 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder7.png'),
    coords: [
      { x: 603, y: 46 },
      { x: 639, y: 198 },
      { x: 657, y: 310 },
      { x: 683, y: 369 },
      { x: 579, y: 265 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder8.png'),
    coords: [
      { x: 570, y: 119 },
      { x: 739, y: 178 },
      { x: 621, y: 277 },
      { x: 715, y: 361 },
      { x: 531, y: 313 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder6.png'),
    coords: [
      { x: 450, y: 58 },
      { x: 575, y: 25 },
      { x: 471, y: 185 },
      { x: 717, y: 361 },
      { x: 761, y: 147 },
      { x: 598, y: 236 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder9.png'),
    coords: [
      { x: 460, y: 55 },
      { x: 465, y: 153 },
      { x: 645, y: 155 },
      { x: 542, y: 248 },
      { x: 759, y: 341 },
      { x: 645, y: 333 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder10.png'),
    coords: [
      { x: 458, y: 186 },
      { x: 565, y: 43 },
      { x: 641, y: 121 },
      { x: 708, y: 123 },
      { x: 782, y: 276 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder16.png'),
    coords: [
      { x: 602, y: 104 },
      { x: 568, y: 208 },
      { x: 776, y: 385 },
      { x: 461, y: 301 },
      { x: 569, y: 269 },
      { x: 636, y: 357 },
    ],
  },
  {
    id: 11,
    image: require('assets/images/pathfinder/pathfinder21.jpeg'),
    coords: [
      { x: 635, y: 106 },
      { x: 634, y: 187 },
      { x: 735, y: 352 },
      { x: 651, y: 309 },
      { x: 526, y: 326 },
    ],
  },
  {
    id: 12,
    image: require('assets/images/pathfinder/pathfinder24.png'),
    coords: [
      { x: 476, y: 221 },
      { x: 747, y: 30 },
      { x: 702, y: 272 },
      { x: 465, y: 516 },
      { x: 447, y: 426 },
    ],
  },
  {
    id: 13,
    image: require('assets/images/pathfinder/pathfinder30.jpg'),
    coords: [
      { x: 745, y: 68 },
      { x: 704, y: 191 },
      { x: 752, y: 324 },
      { x: 589, y: 280 },
      { x: 507, y: 152 },
    ],
  },
  {
    id: 14,
    image: require('assets/images/pathfinder/pathfinder31.jpg'),
    coords: [
      { x: 663, y: 69 },
      { x: 557, y: 124 },
      { x: 602, y: 205 },
      { x: 669, y: 273 },
      { x: 539, y: 283 },
    ],
  },
  {
    id: 15,
    image: require('assets/images/pathfinder/pathfinder40.png'),
    coords: [
      { x: 522, y: 340 },
      { x: 672, y: 252 },
      { x: 584, y: 206 },
      { x: 533, y: 67 },
      { x: 541, y: 166 },
    ],
  },
  {
    id: 16,
    image: require('assets/images/pathfinder/pathfinder32.jpg'),
    coords: [
      { x: 750, y: 202 },
      { x: 601, y: 377 },
      { x: 603, y: 155 },
      { x: 523, y: 151 },
      { x: 447, y: 341 },
    ],
  },
  {
    id: 17,
    image: require('assets/images/pathfinder/pathfinder33.jpg'),
    coords: [
      { x: 603, y: 49 },
      { x: 655, y: 193 },
      { x: 672, y: 323 },
      { x: 584, y: 335 },
      { x: 534, y: 323 },
    ],
  },
  {
    id: 18,
    image: require('assets/images/pathfinder/pathfinder34.jpg'),
    coords: [
      { x: 664, y: 142 },
      { x: 542, y: 52 },
      { x: 469, y: 257 },
      { x: 496, y: 315 },
      { x: 568, y: 255 },
    ],
  },
  {
    id: 19,
    image: require('assets/images/pathfinder/pathfinder35.jpg'),
    coords: [
      { x: 575, y: 116 },
      { x: 725, y: 167 },
      { x: 442, y: 224 },
      { x: 649, y: 201 },
      { x: 632, y: 137 },
    ],
  },
  {
    id: 20,
    image: require('assets/images/pathfinder/pathfinder36.jpg'),
    coords: [
      { x: 599, y: 151 },
      { x: 724, y: 211 },
      { x: 462, y: 210 },
      { x: 597, y: 370 },
      { x: 568, y: 123 },
      { x: 626, y: 122 },
      { x: 699, y: 370 },
    ],
  },
];

const QUESTIONS_MEDIUM = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder4.jpeg'),
    coords: [
      { x: 450, y: 378 },
      { x: 690, y: 452 },
      { x: 631, y: 25 },
      { x: 537, y: 65 },
      { x: 474, y: 227 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder17.png'),
    coords: [
      { x: 609, y: 283 },
      { x: 758, y: 200 },
      { x: 556, y: 118 },
      { x: 526, y: 263 },
      { x: 582, y: 236 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder18.png'),
    coords: [
      { x: 668, y: 197 },
      { x: 449, y: 354 },
      { x: 640, y: 87 },
      { x: 543, y: 91 },
      { x: 541, y: 239 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder23.jpeg'),
    coords: [
      { x: 533, y: 202 },
      { x: 620, y: 225 },
      { x: 543, y: 317 },
      { x: 622, y: 292 },
      { x: 739, y: 280 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder26.jpeg'),
    coords: [
      { x: 518, y: 27 },
      { x: 568, y: 142 },
      { x: 453, y: 330 },
      { x: 626, y: 406 },
      { x: 545, y: 270 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder27.jpeg'),
    coords: [
      { x: 519, y: 112 },
      { x: 587, y: 166 },
      { x: 555, y: 226 },
      { x: 497, y: 391 },
      { x: 765, y: 409 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder28.jpeg'),
    coords: [
      { x: 529, y: 368 },
      { x: 575, y: 156 },
      { x: 494, y: 288 },
      { x: 727, y: 151 },
      { x: 674, y: 230 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder37.jpeg'),
    coords: [
      { x: 716, y: 88 },
      { x: 732, y: 153 },
      { x: 637, y: 149 },
      { x: 512, y: 197 },
      { x: 431, y: 277 },
      { x: 523, y: 361 },
      { x: 771, y: 309 },
      { x: 750, y: 361 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder38.jpeg'),
    coords: [
      { x: 652, y: 147 },
      { x: 744, y: 76 },
      { x: 583, y: 65 },
      { x: 512, y: 257 },
      { x: 620, y: 377 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder39.jpeg'),
    coords: [
      { x: 592, y: 21 },
      { x: 462, y: 201 },
      { x: 596, y: 236 },
      { x: 640, y: 249 },
      { x: 516, y: 367 },
    ],
  },
];

const QUESTIONS_HARD = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder11.jpeg'),
    coords: [
      { x: 727, y: 156 },
      { x: 751, y: 233 },
      { x: 443, y: 500 },
      { x: 768, y: 377 },
      { x: 500, y: 284 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder12.jpeg'),
    coords: [
      { x: 661, y: 60 },
      { x: 604, y: 422 },
      { x: 690, y: 443 },
      { x: 477, y: 425 },
      { x: 490, y: 157 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder13.png'),
    coords: [
      { x: 565, y: 295 },
      { x: 596, y: 178 },
      { x: 459, y: 25 },
      { x: 640, y: 307 },
      { x: 722, y: 187 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder14.png'),
    coords: [
      { x: 578, y: 39 },
      { x: 665, y: 92 },
      { x: 469, y: 240 },
      { x: 619, y: 384 },
      { x: 782, y: 283 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder15.png'),
    coords: [
      { x: 663, y: 380 },
      { x: 692, y: 243 },
      { x: 620, y: 176 },
      { x: 723, y: 100 },
      { x: 506, y: 104 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder19.png'),
    coords: [
      { x: 677, y: 235 },
      { x: 494, y: 206 },
      { x: 629, y: 172 },
      { x: 619, y: 119 },
      { x: 680, y: 122 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder20.jpg'),
    coords: [
      { x: 738, y: 49 },
      { x: 417, y: 273 },
      { x: 647, y: 405 },
      { x: 740, y: 296 },
      { x: 462, y: 332 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder22.jpeg'),
    coords: [
      { x: 773, y: 40 },
      { x: 703, y: 132 },
      { x: 741, y: 206 },
      { x: 553, y: 241 },
      { x: 652, y: 293 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder25.jpeg'),
    coords: [
      { x: 454, y: 28 },
      { x: 686, y: 31 },
      { x: 550, y: 18 },
      { x: 547, y: 272 },
      { x: 602, y: 376 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder29.png'),
    coords: [
      { x: 750, y: 179 },
      { x: 511, y: 288 },
      { x: 554, y: 244 },
      { x: 591, y: 236 },
      { x: 533, y: 166 },
    ],
  },
];

interface Props {
  level: DifficultLevelType;
  nextStep: () => void;
}

const PathfinderGame = ({ level, nextStep }: Props) => {
  const notify = useNotification();

  const [disabledImage, setDisabledImage] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [foundDifferences, setFoundDifferences] = useState<Record<string, number>[]>([]);
  const [wrongClicks, setWrongClicks] = useState<Record<string, number>[]>([]);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [clickCount, setClickCount] = useState<number>(0);
  const MAX_CLICKS = 10;
  const START_TIME: Date = new Date();
  const timer = level === 'easy' ? 180 : level === 'medium' ? 120 : 60;
  const questions =
    level === 'easy' ? QUESTIONS_EASY : level === 'medium' ? QUESTIONS_MEDIUM : QUESTIONS_HARD;

  const sendGameStats = () => {
    const END_TIME: Date = new Date();
    const time = END_TIME.getTime() - START_TIME.getTime();
    const result: TData = {
      game: 'images',
      difficulty: 'medium',
      score: countCorrectAnswers + foundDifferences.length,
      time,
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const handleNextQuestion = (nextQuestionIndex: number) => {
    if (questions.length < nextQuestionIndex) {
      sendGameStats();
    } else {
      setCountCorrectAnswers((prevState) => prevState + foundDifferences.length);
      setDisabledImage(false);
      setFoundDifferences([]);
      setWrongClicks([]);
      setClickCount(0);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleClickImage = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    differences: { [key: string]: number }[]
  ) => {
    if (disabledImage) return;

    if (clickCount >= MAX_CLICKS) {
      notify.error(
        'Вы достигли максимального количества кликов! Вы будете перенесены на следующий уровень.'
      );
      setTimeout(() => handleNextQuestion(currentQuestionIndex + 1), 2000);
      return;
    }

    const imgElement = e.target as HTMLImageElement;
    const rect = imgElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    let isCorrect = false;
    console.log('x - ', x, ' y - ', y);

    differences.forEach((diff) => {
      if (x >= diff.x - 30 && x <= diff.x + 30 && y >= diff.y - 30 && y <= diff.y + 30) {
        isCorrect = true;
        if (!foundDifferences.some((fd) => fd.x === diff.x && fd.y === diff.y)) {
          setFoundDifferences([...foundDifferences, diff]);
        }
      }
    });

    if (!isCorrect) {
      setWrongClicks([...wrongClicks, { x, y }]);
      setClickCount(clickCount + 1);
    }
  };

  const handleFinishTimer = () => {
    notify.error('Время вышло! Вы переходите на следующий уровень.');
    setTimeout(() => handleNextQuestion(currentQuestionIndex + 1), 2000);
  };

  useEffect(() => {
    if (foundDifferences.length > 4) {
      setDisabledImage(true);
      notify.success('Вы прошли уровень! Чтобы перейти на следующий уровень, нажмите "Далее".');
    }
  }, [foundDifferences]);

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
      {questions.map((question, index) => {
        return currentQuestionIndex === index + 1 ? (
          <div key={index} className="flex flex-col space-y-8">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="font-base opacity-50">Задание №{index + 1}</p>
              <Timer time={timer} className="!text-[16px]" onClose={handleFinishTimer} />
            </div>
            <div className="flex flex-col items-center max-w-[800px] w-full mx-auto">
              <p className="max-w-[450px] text-[24px] font-semibold text-center leading-8 mb-4">
                Внимательно сравните обе картинки и найдите все отличия между ними
              </p>
              <p className="text-[18px] text-center mb-7">
                Картинка слева правильная, а на картинке справа есть 5 отличий.
              </p>
              <div className="mb-4 relative">
                <img
                  className="w-full h-auto object-cover object-center"
                  alt={`pathfinder-${question.id}`}
                  src={question.image}
                  onClick={(e) => handleClickImage(e, question.coords)}
                />
                {foundDifferences.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-green rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
                {wrongClicks.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-red-500 rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
              </div>
              <div className="flex flex-row items-center justify-center opacity-50 space-x-2 mb-10">
                <Finger />
                <p className="text-[18px]">Нажмите на точку на картинке, где вы нашли отличие.</p>
              </div>
              <LMSButton
                className="self-center"
                suffix={<ArrowRight />}
                disabled={foundDifferences.length < 5}
                onClick={() => handleNextQuestion(index + 1 + 1)}
              >
                Далее
              </LMSButton>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default PathfinderGame;
