import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/close.svg';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import { fetchStudentLessons } from 'modules/coach/api/repository/courses/students';
import { useNotification } from 'hooks/notification';

import { ILesson } from '../../entities/coach-courses/Students';

const CoachCourseStudentLessons = () => {
  const { id, groupId, studentId } = useParams();
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState(1);
  const [openLessons, setOpenLessons] = useState<{ [key: number]: boolean }>({});

  const handleDropdownClick = (index: number) => {
    setOpenLessons((prevOpenLessons) => ({
      ...prevOpenLessons,
      [index]: !prevOpenLessons[index],
    }));
  };

  const setCurrentPage = (current: number) => {
    setPage(current);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStudentLessons(Number(studentId), Number(groupId), Number(id), 10, page)
      .then((res) => {
        const { studentLessons, lastPage } = res;
        setLessons(studentLessons.lessons);
        setLastPage(lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);

  return (
    <div className="flex flex-col gap-8 p-6 theme-background-default-table rounded-tr-[26px] rounded-b-[26px]">
      <div className="w-full h-full flex flex-col">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <table className="hidden w-full table-auto border-separate border-spacing-1 md:table">
              <thead>
                <tr className="h-12 text-white text-sm font-normal text-opacity-40">
                  <th className="w-[5%] text-left">№</th>
                  <th className="w-[40%] text-left">Название урока</th>
                  <th className="w-[30%] text-left">Дата просмотра</th>
                  <th className="w-[20%] text-left">Посещаемость</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {lessons &&
                  lessons.map((el, index) => (
                    <tr
                      key={index}
                      className="w-full text-white text-base font-normal tracking-wide"
                    >
                      <td className="h-16 text-white text-lg">{el.id}</td>
                      <td className="h-16 flex flex-row items-center gap-x-5">{el.lessonName}</td>
                      <td className="h-16">{el.viewDate ? el.viewDate.toString() : '-'}</td>
                      <td className="h-16">
                        {el.isPresent ? (
                          <div className="flex flex-row items-center gap-6">
                            <SuccessIcon className="text-green" />
                            Посетил
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-6">
                            <NegativeIcon className="w-6 text-[#FF5449]" />
                            Пропустил
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex flex-col md:hidden">
              {lessons &&
                lessons.map((el, idx) => (
                  <div key={idx}>
                    <div
                      onClick={() => handleDropdownClick(idx)}
                      className="w-full py-3 text-white text-base font-normal border-t border-white border-opacity-10 cursor-pointer first-of-type:border-none"
                    >
                      <div className="flex flex-row gap-1 text-xs">
                        №<span>{el.id}</span>
                      </div>
                      <div className="w-full flex items-baseline justify-between">
                        {el.lessonName}
                        <DropdownIcon
                          className={`${openLessons[idx] ? 'rotate-180' : ''} min-w-max`}
                        />
                      </div>
                    </div>
                    {openLessons[idx] && (
                      <div className="grid grid-cols-2 py-3">
                        <div className="flex flex-col">
                          <span className="text-white text-opacity-40">Дата просмотра</span>
                          <span className="text-white">
                            {el.viewDate ? el.viewDate.toString() : '-'}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-white text-opacity-40">Посещаемость</span>
                          <div className="text-white">
                            {el.isPresent ? (
                              <div className="flex flex-row items-center gap-1">
                                <SuccessIcon className="text-green" />
                                Посетил
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-1">
                                <NegativeIcon className="w-6 text-[#FF5449]" />
                                Пропустил
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>

      <div className="w-full flex justify-start items-center mt-auto pt-6 border-white border-t border-opacity-40 md:pt-10">
        <LMSPagination current={page} lastPage={lastPage} onChange={setCurrentPage} />
      </div>
    </div>
  );
};

export default CoachCourseStudentLessons;
