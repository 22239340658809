import React from 'react';
import LMSButton from 'components/LMSButton';
import { ISimulationDetail } from 'modules/coach/entities/Simulation';

interface Props {
  simulation: ISimulationDetail;
  nextStep: () => void;
}

const LectioWelcome = ({ simulation, nextStep }: Props) => {
  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] h-[500px] rounded-[20px] theme-student-card m-auto">
      <div className="flex flex-col space-y-4 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">
          Добро пожаловать в игру &quot;{simulation.categoryName}&quot;
        </p>
        <p className="text-[22px] text-center leading-6 max-w-[700px]">
          Цель игры научить вас различать латиницу, чтобы вы могли правильно произносить и различать
          различные названия на латинице, особенно те, которые имеют схожее написание. Это поможет
          вам уверенно работать с товарами или документами, где важно различать похожие латинские
          названия.
        </p>
      </div>
      <LMSButton onClick={nextStep}>Начать</LMSButton>
    </div>
  );
};

export default LectioWelcome;
