import React, { useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import LMSButton from 'components/LMSButton';
import TimerProgressBar from 'components/TimerProgressBar';
import { ISimulationQuestion } from 'modules/coach/entities/Simulation';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import Timer from '../Timer';

interface Props {
  questions: ISimulationQuestion[];
  nextStep: () => void;
}

const LectioTest = ({ questions, nextStep }: Props) => {
  const notify = useNotification();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [answer, setAnswer] = useState<string>('');
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [showQuestion, setShowQuestion] = useState<boolean>(true);
  const START_TIME: Date = new Date();

  const sendGameStats = () => {
    const END_TIME: Date = new Date();
    const time = END_TIME.getTime() - START_TIME.getTime();
    const result: TData = {
      game: 'lectio',
      difficulty: 'medium',
      score: countCorrectAnswers,
      time,
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const nextQuestion = (nextQuestionIndex: number, isCorrect: boolean) => {
    isCorrect && setCountCorrectAnswers((prevState) => prevState + 1);
    if (questions.length < nextQuestionIndex) {
      sendGameStats();
    } else {
      setAnswer('');
      setShowQuestion(true);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const checkAnswer = (
    nextQuestionIndex: number,
    userAnswer: string,
    questionAnswerValue: ISimulationQuestion['answers']
  ) => {
    let correctAnswer: string;
    if (Array.isArray(questionAnswerValue)) {
      const foundAnswer = questionAnswerValue.find((value) => value.isCorrect);
      correctAnswer = foundAnswer ? foundAnswer.name : '';
    } else {
      correctAnswer = questionAnswerValue.name;
    }
    nextQuestion(nextQuestionIndex, userAnswer === correctAnswer.toLowerCase());
  };

  const handleFinishTimer = () => {
    notify.error('Время вышло! Вы переходите к следующему вопросу.');
    setTimeout(() => nextQuestion(currentQuestionIndex + 1, false), 2000);
  };

  const getQuestionCondition = (questionTypeId: number): string => {
    switch (questionTypeId) {
      case 2:
        return 'Выберите слово, которое было показано на экране';
      case 3:
        return 'Напишите слово, которое было показано на экране';
      default:
        return 'Внимательно посмотрите на слово и напишите его правильно';
    }
  };

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
      {questions.map((question, index) => {
        return currentQuestionIndex === index + 1 ? (
          <div key={index} className="flex flex-col space-y-8">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="font-base opacity-50">Задание №{index + 1}</p>
              <Timer time={120} className="!text-[16px]" onClose={handleFinishTimer} />
            </div>
            <div className="flex flex-col max-w-[460px] min-w-[340px] w-full mx-auto">
              {showQuestion && (
                <>
                  <p className="text-[18px] text-center leading-6 mb-7">
                    Запомните написание слова, которое видите, и выберите правильный ответ после
                    окончания времени.
                  </p>
                  <div className="flex items-center justify-center mb-4">
                    {question.typeId === 3 && question.seconds ? (
                      <div className="flex flex-col">
                        <p className="text-[60px] font-bold text-center">{question.title}</p>
                        <TimerProgressBar
                          duration={question.seconds}
                          onCallback={() => setShowQuestion(false)}
                        />
                      </div>
                    ) : (
                      <p className="text-[60px] font-bold text-center">{question.title}</p>
                    )}
                  </div>
                </>
              )}
              {question.typeId === 3 && question.seconds && showQuestion ? null : (
                <>
                  <p className="text-[24px] font-semibold text-center leading-8 mb-7">
                    {getQuestionCondition(question.typeId)}
                  </p>
                  {Array.isArray(question.answers) ? (
                    <div className="flex flex-col space-y-4 mb-7">
                      {question.answers.map((questionAnswer, idx) => (
                        <button
                          key={idx}
                          type="button"
                          className={`h-[55px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6] border-2 border-transparent rounded-[14px] outline-none text-black ${
                            questionAnswer.name === answer && '!border-green'
                          }`}
                          onClick={() => setAnswer(questionAnswer.name)}
                        >
                          {questionAnswer.name}
                          {questionAnswer.name === answer && <SuccessIcon />}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <input
                      className="max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white mb-7"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                  )}
                  <LMSButton
                    className="self-center"
                    disabled={!answer}
                    suffix={<ArrowRight />}
                    onClick={() =>
                      checkAnswer(index + 1 + 1, answer.trim().toLowerCase(), question.answers)
                    }
                  >
                    Далее
                  </LMSButton>
                </>
              )}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default LectioTest;
