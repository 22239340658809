import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { fetchSimulation } from 'modules/coach/api/repository/simulations';
import { ISimulationDetail } from 'modules/coach/entities/Simulation';
import { useNotification } from 'hooks/notification';

import LectioResult from '../../components/LectioResult';
import LectioTest from '../../components/LectioTest';
import LectioWelcome from '../../components/LectioWelcome';

const Simulation = () => {
  const { id } = useParams();
  const notify = useNotification();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [simulation, setSimulation] = useState<ISimulationDetail | null>(null);

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleExit = () => navigate('/student');

  useEffect(() => {
    setIsLoading(true);
    fetchSimulation(Number(id))
      .then((res) => {
        setSimulation(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-start justify-center m-auto">
        <Loader />
      </div>
    );
  }

  if (!simulation) {
    return null;
  }

  return (
    <div className="flex w-full h-full min-h-[100vh] relative">
      <div className="w-full h-full p-10 m-auto">
        {step === 1 ? (
          <LectioWelcome simulation={simulation} nextStep={nextStep} />
        ) : step === 2 ? (
          <LectioTest questions={simulation.lectioQuestions} nextStep={nextStep} />
        ) : (
          <LectioResult simulation={simulation} />
        )}
      </div>
      <button
        type="button"
        className="h-[40px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6]/50 rounded-[14px] outline-none text-black absolute top-5 right-5"
        onClick={handleExit}
      >
        Выйти
      </button>
    </div>
  );
};

export default Simulation;
