import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/close.svg';
import { ReactComponent as ProgressIcon } from 'assets/icons/progress.svg';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import ProgressBar from 'components/ProgressBar';
import { fetchStudentHomeworks } from 'modules/coach/api/repository/courses/students';
import { useNotification } from 'hooks/notification';

import { IHomework } from '../../entities/coach-courses/Students';

const CoachCourseStudentHomeWork = () => {
  const { id, groupId, studentId } = useParams();
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [homeworks, setHomeworks] = useState<IHomework[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState(1);
  const [openHomeWork, setOpenHomeWork] = useState<{ [key: number]: boolean }>({});

  const handleDropdownClick = (index: number) => {
    setOpenHomeWork((prevOpenHomeWork) => ({
      ...prevOpenHomeWork,
      [index]: !prevOpenHomeWork[index],
    }));
  };

  const setCurrentPage = (current: number) => {
    setPage(current);
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case 'Сдано':
        return (
          <>
            <SuccessIcon className="text-green" />
            Сдано
          </>
        );
      case 'На доработке':
      case 'На проверке':
        return (
          <>
            <ProgressIcon className="text-orange-300" />
            {status}
          </>
        );
      default:
        return (
          <>
            <NegativeIcon className="w-6 text-[#FF5449]" />
            Не сдано
          </>
        );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStudentHomeworks(Number(studentId), Number(groupId), Number(id), 10, page)
      .then((res) => {
        const { studentHomeworks, lastPage } = res;
        setHomeworks(studentHomeworks.hometasks);
        setLastPage(lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);

  return (
    <div className="flex flex-col gap-8 p-6 theme-background-default-table rounded-tr-[26px] rounded-b-[26px]">
      <div className="w-full h-full flex flex-col">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <table className="hidden w-full table-auto border-separate border-spacing-1 md:table">
              <thead>
                <tr className="h-12 text-white text-sm font-normal text-opacity-40">
                  <th className="w-[5%] text-left">№</th>
                  <th className="w-[35%] text-left">Название задания</th>
                  <th className="w-[10%] text-left">Дата сдачи</th>
                  <th className="w-[10%] text-left">Дата дедлайна</th>
                  <th className="w-[15%] text-left">Статус</th>
                  <th className="w-[15%] text-left">Оценка</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {homeworks &&
                  homeworks.map((el, index) => (
                    <tr
                      key={index}
                      className="w-full text-white text-base font-normal tracking-wide"
                    >
                      <td className="h-16 text-white text-lg">{el.id + 1}</td>
                      <td className="h-16 flex flex-row items-center gap-x-5">{el.hometaskName}</td>
                      <td className="h-16">{el.finishDate ? el.finishDate.toString() : '-'}</td>
                      <td className="h-16">{el.deadlineDate ? el.deadlineDate.toString() : '-'}</td>
                      <td className="h-16">
                        <div className="flex flex-row items-center gap-2">
                          {renderStatus(el.status)}
                        </div>
                      </td>
                      <td className="h-16">{el.mark ? el.mark : '-'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex flex-col md:hidden">
              {homeworks &&
                homeworks.map((el, idx) => (
                  <div key={idx}>
                    <div
                      onClick={() => handleDropdownClick(idx)}
                      className="w-full py-3 text-white text-base font-normal border-t border-white border-opacity-10 cursor-pointer"
                    >
                      <div className="flex flex-row gap-1 text-xs">
                        №<span>{idx + 1}</span>
                      </div>
                      <div className="w-full flex items-baseline justify-between text-lg">
                        {el.hometaskName}
                        <DropdownIcon className={`${openHomeWork[idx] ? 'rotate-180' : ''}`} />
                      </div>
                    </div>
                    {openHomeWork[idx] && (
                      <div className="w-full py-3">
                        <div className="w-full flex flex-col">
                          <span className="text-white text-opacity-40">Оценка</span>
                          <span className="text-white pb-4">{el.mark ? el.mark : '-'}</span>
                          {el.mark && (
                            <ProgressBar
                              label={false}
                              progress={Number(el.mark.split(' ')[0])}
                              maxProgress={100}
                            />
                          )}
                        </div>
                        <div className="w-full flex flex-row gap-x-4 py-4">
                          <div className="flex flex-col flex-1 text-white">
                            <span className="text-white text-opacity-20">Дата сдачи</span>
                            <span>{el.finishDate ? el.finishDate.toString() : '-'}</span>
                          </div>
                          <div className="flex flex-col flex-1 text-white">
                            <span className="text-white text-opacity-20">Дата дедлайна</span>
                            <span>{el.deadlineDate ? el.deadlineDate.toString() : '-'}</span>
                          </div>
                          <div className="flex-1 text-white">
                            <span className="text-white text-opacity-20">Статус</span>
                            <div className="flex flex-row items-center gap-2">
                              {renderStatus(el.status)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>

      <div className="w-full flex justify-start items-center mt-auto pt-6 border-white border-t border-opacity-40 md:pt-10">
        <LMSPagination current={page} lastPage={lastPage} onChange={setCurrentPage} />
      </div>
    </div>
  );
};

export default CoachCourseStudentHomeWork;
