import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PathfinderDifficultLevel from '../../components/PathfinderDifficultLevel';
import PathfinderGame from '../../components/PathfinderGame';
import PathfinderResult from '../../components/PathfinderResult';
import PathfinderWelcome from '../../components/PathfinderWelcome';

type DifficultLevelType = 'easy' | 'medium' | 'hard';

const Pathfinder = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [difficultLevel, setDifficultLevel] = useState<DifficultLevelType>('medium');

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleExit = () => navigate('/student');

  const renderStep = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <PathfinderWelcome
            title='Добро пожаловать в игру "Следопыт"'
            desc="Цель игры  – развить вашу внимательность, находя различия между двумя изображениями. Это поможет вам улучшить навыки наблюдательности, которые важны при приеме товаров в магазинах, супермаркетах и гипермаркетах."
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <PathfinderWelcome
            title="Отметка различий"
            desc={`- Правильные отметки будут подсвечены зеленым цветом.\n- Неправильные отметки будут подсвечены красным цветом.\n- У вас есть 10 попыток для каждой пары изображений`}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <PathfinderWelcome
            title="Ограничение по времени"
            desc={`Внимательно следите за таймером в верхней части экрана.\n- У вас есть ограниченное время для нахождения всех различий в зависимости от выбранного уровня сложности.\n- Продолжайте находить различия до завершения всех пар изображений.`}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <PathfinderDifficultLevel
            onChange={(level) => {
              setDifficultLevel(level);
              nextStep();
            }}
          />
        );
      case 5:
        return <PathfinderGame level={difficultLevel} nextStep={nextStep} />;
      default:
        return <PathfinderResult maxScore={difficultLevel === 'easy' ? 100 : 50} />;
    }
  }, [step]);

  return (
    <div className="flex w-full h-full min-h-[100vh] relative">
      <div className="w-full h-full p-10 m-auto">{renderStep}</div>
      <button
        type="button"
        className="h-[40px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6]/50 rounded-[14px] outline-none text-black absolute top-5 right-5"
        onClick={handleExit}
      >
        Выйти
      </button>
    </div>
  );
};

export default Pathfinder;
