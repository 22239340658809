import request from 'api/axios-instance';

import {
  IQuestionAnswer,
  ISimulation,
  ISimulationDetail,
  ISimulationQuestion,
} from '../../entities/Simulation';
import {
  IQuestionAnswerDao,
  ISimulationDao,
  ISimulationDetailDao,
  ISimulationQuestionDao,
} from '../dao/Simulation.dao';

export const fetchSimulations = (page?: number, perPage?: number) =>
  request.get('v2/game/lectio/simulations', { params: { page, perPage } }).then((res) => {
    const simulations: ISimulation[] = res.data.data.map(simulationDaoToEntity);
    return { simulations, lastPage: res?.data?.meta?.last_page || 1 };
  });

export const fetchSimulation = (id: number) =>
  request.get(`v2/game/lectio/simulations/${id}`).then((res) => {
    const simulation: ISimulationDetail = simulationDetailDaoToEntity(res.data.data);
    return simulation;
  });

export const createSimulation = (data: Record<string, string | number>) =>
  request.post(`v2/game/lectio/simulations`, data).then((res) => res.data);

export const updateSimulation = (id: number, data: Record<string, string | number>) =>
  request.post(`v2/game/lectio/simulations/${id}`, data).then((res) => res.data);

export const deleteSimulation = (id: number) =>
  request.delete(`v2/game/lectio/simulations/${id}`).then((res) => res.data);

export const postSimulationQuestions = (id: number, data: ISimulationQuestion[]) => {
  const questionEntity = data.map(questionEntityToDao);
  return request.post(`v2/game/lectio/simulations/${id}/questions`, {
    questions: questionEntity,
  });
};

function simulationDaoToEntity(simulationDao: ISimulationDao): ISimulation {
  return {
    id: simulationDao.id,
    target: simulationDao.target,
    description: simulationDao.description,
    instruction: simulationDao.instruction,
    categoryDescription: simulationDao.category_description,
    questions: simulationDao.questions,
    timerDescription: simulationDao.timer_description,
    tryDescription: simulationDao.try_description,
    resultsDescription: simulationDao.results_description,
    categoryId: simulationDao.category_id,
    categoryName: simulationDao.category_name,
    seconds: simulationDao.seconds,
  };
}

function simulationDetailDaoToEntity(simulationDao: ISimulationDetailDao): ISimulationDetail {
  return {
    id: simulationDao.id,
    target: simulationDao.target,
    description: simulationDao.description,
    instruction: simulationDao.instruction,
    categoryDescription: simulationDao.category_description,
    questions: simulationDao.questions,
    timerDescription: simulationDao.timer_description,
    tryDescription: simulationDao.try_description,
    resultsDescription: simulationDao.results_description,
    categoryId: simulationDao.category_id,
    categoryName: simulationDao.category_name,
    seconds: simulationDao.seconds,
    lectioQuestions: simulationDao.lectioQuestions.map(questionDaoToEntity),
  };
}

function questionDaoToEntity(questionDao: ISimulationQuestionDao): ISimulationQuestion {
  return {
    id: questionDao.id,
    title: questionDao.title,
    typeId: questionDao.type_id,
    typeName: questionDao.type_name,
    answers: Array.isArray(questionDao.answers)
      ? questionDao.answers.map(answerDaoToEntity)
      : answerDaoToEntity(questionDao.answers),
    seconds: questionDao.seconds,
    audio: questionDao.audio,
    newAudio: questionDao.new_audio,
    position: questionDao.position,
  };
}

function answerDaoToEntity(answerDao: IQuestionAnswerDao): IQuestionAnswer {
  return {
    name: answerDao.name,
    isCorrect: answerDao.is_correct,
  };
}

function simulationDetailEntityToDao(simulation: ISimulationDetail): ISimulationDetailDao {
  return {
    id: simulation.id,
    target: simulation.target,
    description: simulation.description,
    instruction: simulation.instruction,
    category_description: simulation.categoryDescription,
    questions: simulation.questions,
    timer_description: simulation.timerDescription,
    try_description: simulation.tryDescription,
    results_description: simulation.resultsDescription,
    category_id: simulation.categoryId,
    category_name: simulation.categoryName,
    seconds: simulation.seconds,
    lectioQuestions: simulation.lectioQuestions.map(questionEntityToDao),
  };
}

function questionEntityToDao(question: ISimulationQuestion): ISimulationQuestionDao {
  return {
    id: question.id,
    title: question.title,
    type_id: question.typeId,
    type_name: question.typeName,
    answers: Array.isArray(question.answers)
      ? question.answers.map(answerEntityToDao)
      : answerEntityToDao(question.answers),
    seconds: question.seconds,
    audio: question.audio,
    new_audio: question.newAudio,
    position: question.position,
  };
}

function answerEntityToDao(answer: IQuestionAnswer): IQuestionAnswerDao {
  return {
    name: answer.name,
    is_correct: answer.isCorrect,
  };
}
